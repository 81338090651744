import useActiveMentor from '../../../hooks/api/mentors/useActiveMentor';
import { useSelector } from 'react-redux';
import { textTruncate, userIsAdmin } from '../../../utils/shared';
import { useDebounce } from '../../../hooks/useDebounce';

export default function FeaturedMentorInfoModal() {
  const mentorToView = useSelector((state) => state.mentors.mentorToView);
  const TOOLTIP_TRUNCATE_LIMIT = 200;
  const {
    handleSelectMentor,
    handleCloseFeaturedMentorInfoModal,
    handleOpenMentorSettings,
    handleFeaturedMentorHover,
  } = useActiveMentor(mentorToView);

  useDebounce(handleFeaturedMentorHover, 200, []);

  return (
    <div
      react-component="HelpModalBox"
      className="modal featured-mentor-info-modal"
    >
      <div className="w-layout-vflex modal-container featured-mentor-info-modal-container">
        <div className="w-layout-vflex modal-body featured-mentor-info-modal-body">
          <div className="w-layout-vflex ai-expert-element-top-area detailed-version">
            <div
              onClick={handleCloseFeaturedMentorInfoModal}
              data-w-id="f02f8c54-2ef6-ec0d-0248-c55f14221e6a"
              className="modal-close-wrapper featured-mentor-info-modal-closer"
            >
              <img
                loading="lazy"
                src="/images/close_1close.png"
                alt="Close Button Image"
              />
            </div>
            {userIsAdmin() && (
              <div
                data-w-id="85b60915-91ed-31f3-5f46-dd7d70fe7b8f"
                onClick={handleOpenMentorSettings}
                className="w-layout-hflex featured-mentor-info-settings-btn light-grey-bg-dark-mode greyish-bg-in-darkmode all-whiten-in-dark-mode"
              >
                <img
                  src="/images/cog.svg"
                  loading="lazy"
                  alt=""
                  className="image-60"
                />
                <div className="text-block-57">Settings</div>
              </div>
            )}

            <div
              className="w-layout-hflex expert-profile-container detailed-version cloud-computer"
              style={{
                backgroundImage: `url('${mentorToView?.settings?.profile_image}')`,
              }}
            ></div>
            <div className="w-layout-vflex ai-expert-desc-block">
              <h5
                prop-data-value-name=""
                prop-data-names="name"
                className="ai-expert-title detailed-version"
              >
                {mentorToView?.name}
              </h5>
              <div className="w-layout-hflex ai-expert-desc-container detailed-version">
                <div
                  prop-data-value-description="Your AI Work Study/Buddy"
                  prop-data-names="description"
                  className="ai-expert-desc"
                >
                  {textTruncate(
                    mentorToView?.description,
                    TOOLTIP_TRUNCATE_LIMIT
                  )}
                </div>
              </div>
              {String(mentorToView?.description).length >
                TOOLTIP_TRUNCATE_LIMIT && (
                <div className="w-layout-hflex featured-mentor-tooltip light-grey-bg-dark-mode greyish-bg-in-darkmode">
                  <div className="featured-mentor-tooltip-text">
                    {mentorToView?.description}
                  </div>
                </div>
              )}
            </div>
            <div className="w-layout-vflex flex-block-97">
              <div
                onClick={handleSelectMentor}
                className="w-layout-hflex featured-mentor-info-btn primary"
              >
                <div>Start Chatting</div>
              </div>
              <div
                data-w-id="d342d955-d38d-d64f-8e00-8cd4d5e0eefb"
                onClick={handleCloseFeaturedMentorInfoModal}
                className="w-layout-hflex featured-mentor-info-btn greyish-bg-in-darkmode light-grey-bg-dark-mode all-whiten-in-dark-mode"
              >
                <div>Cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
