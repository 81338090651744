import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import useSuggestedPrompt from './useSuggestedPrompt';
import useApi from '../api/base/useApi';
import { useEffect } from 'react';

export default function useSetChatSession() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const chat = useSelector((state) => state.chat.data);
  const messages = useSelector((state) => state.chat.messages);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();

  const api = useApi();
  const dispatch = useDispatch();

  const createChatSession = async (resetMessages = true) => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/?seq=${chat?.sequence}`;
    const payload = {
      mentor: mentors?.mentor?.name,
    };

    const response = await api.post(endpoint, payload);
    if (response?.data) {
      dispatch(chatActions.sessionUpdated(response?.data.session_id));
      if (resetMessages) {
        dispatch(chatActions.messagesUpdated([]));
      }
    }
  };

  useEffect(() => {
    if (sessionId && messages?.length === 0) {
      const proActivePrompt = mentors?.mentor?.proactive_prompt;
      if (proActivePrompt) {
        window.proActiveSession = sessionId;
        setTimeout(() => {
          handleSuggestedPromptSelection(proActivePrompt);
        }, 400);
      }
    }
  }, [sessionId, messages]);

  return { createChatSession };
}
