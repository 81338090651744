import { BASE_API_URL } from '../../../utils/api';
import { toast } from 'react-toastify';
import useIframeEvents from '../../iframe/useIframeEvents';
import { mentorIsIframe } from '../../../utils/shared';
import { handleLogout } from '@iblai/ibl-web-react-common';

export default function useApi() {
  const { notifyParentOnTokenExpiry } = useIframeEvents();
  const _getHeaders = (isForm) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem('axd_token')}`,
    };
    if (!isForm) {
      headers['Content-Type'] = 'application/json';
    }
    return headers;
  };

  const _handleResponse = async (response) => {
    if (response.ok) {
      try {
        return { data: await response.json() };
      } catch (error) {
        return { data: 'Done' };
      }
    } else {
      if (response.status === 401) {
        if (mentorIsIframe()) {
          notifyParentOnTokenExpiry();
        } else {
          localStorage.setItem(
            'redirect-path',
            `${window.location.pathname}${window.location.search}`
          );
          handleLogout();
        }
      }
      return { error: await response.text() };
    }
  };

  const _postData = async (endpoint, method, data, isForm) => {
    const url = `${BASE_API_URL}${endpoint}`;
    try {
      const response = await fetch(url, {
        method: method,
        body: isForm ? data : JSON.stringify(data),
        headers: _getHeaders(isForm),
      });

      const responseData = await _handleResponse(response);
      if (responseData?.error) {
        console.error(
          `REQUEST_ERROR: ${responseData?.error}; URL: ${method} ${url}; REQUEST_DATA: ${JSON.stringify(data)}`
        );
      }
      return responseData;
    } catch (e) {
      console.log(e);
      toast.error('Could not make request.');
    }
  };

  const post = async (endpoint, data, isForm = false) => {
    return _postData(endpoint, 'POST', data, isForm);
  };

  const put = async (endpoint, data, isForm = false) => {
    return _postData(endpoint, 'PUT', data, isForm);
  };

  const patch = async (endpoint, data, isForm = false) => {
    return _postData(endpoint, 'PATCH', data, isForm);
  };

  const remove = async (endpoint, data = {}, isForm = false) => {
    return _postData(endpoint, 'DELETE', data, isForm);
  };

  const get = async (endpoint, params) => {
    let url = `${BASE_API_URL}${endpoint}`;
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }

    try {
      const response = await fetch(url, {
        headers: _getHeaders(),
      });
      const responseData = await _handleResponse(response);
      if (responseData?.error) {
        console.error(
          `REQUEST_ERROR: ${JSON.stringify(responseData?.data)}; URL: GET ${url};`
        );
      }
      return responseData;
    } catch (e) {
      console.log(e);
    }
  };

  return { get, post, put, patch, remove };
}
