import { useCallback, useEffect, useState } from 'react';
import GoogleDrivePicker from 'google-drive-picker';
import useIntegrationCredential from './useIntegrationCredential';
import useDocumentTrain from './useDocumentTrain';
import { useSelector } from 'react-redux';
import useGetCredentials from './useGetCredentials';

const useGoogleDrivePicker = () => {
  const [authToken, setAuthToken] = useState(null);
  const [driveFiles, setDriveFiles] = useState([]);
  const [openPicker, authRes] = GoogleDrivePicker();
  const { handleCreateIntegration } = useIntegrationCredential();
  const { handleFetchCredentials } = useGetCredentials();
  const { handleDocumentTrain } = useDocumentTrain();
  const [credentialLoaded, setCredentialsLoaded] = useState(false);
  const [isPickerLoaded, setIsPickerLoaded] = useState(false);
  const mentor = useSelector((state) => state.mentors.edit);
  const auth = useSelector((state) => state.auth.data);

  const [credentials, setCredentials] = useState({
    client_id: '',
    developer_key: '',
    client_secret: '',
  });
  // Fetch credentials on mount
  useEffect(() => {
    const fetchCredentials = async () => {
      const creds = await handleFetchCredentials('google', false);
      if (creds.length > 0) {
        setCredentials(creds[0].value);
      }
      setCredentialsLoaded(true);
    };

    if (!credentialLoaded) {
      fetchCredentials();
    }
  }, [
    credentialLoaded,
    credentials.client_id,
    credentials.developer_key,
    handleFetchCredentials,
  ]);

  // Load Google API script if not already loaded
  useEffect(() => {
    const loadGoogleApiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        window.gapi.load('auth', () => {
          setIsPickerLoaded(true);
        });
      };
      document.body.appendChild(script);
    };

    if (!isPickerLoaded) {
      loadGoogleApiScript();
    }
  }, [isPickerLoaded]);

  // Handle file selection
  const handlePickerFileSelected = useCallback((files) => {
    setDriveFiles(files);
  }, []);

  // Process selected files when authToken becomes available
  useEffect(() => {
    const handlePickerFileSelection = async () => {
      const integrationPayload = {
        name: 'google_drive_private',
        platform: auth?.tenant?.key,
        value: {
          auth: {
            client_secret: credentials.client_secret,
            refresh_token: 'test-refresh token',
            client_id: credentials.client_id,
            token: authToken.access_token,
            scope: authToken.scope,
            token_type: authToken.token_type,
            expiry_date: new Date(
              Date.now() + authToken.expires_in * 1000
            ).toISOString(),
          },
          data: driveFiles.map((file) => ({
            path: file.url,
            type: file.type,
          })),
        },
      };
      const trainPayload = {
        pathway: mentor?.mentor?.name,
        url: driveFiles.map((file) => file.url).join(','),
        type: 'google_drive_private',
        access: 'private',
      };
      await handleCreateIntegration(integrationPayload);
      await handleDocumentTrain(trainPayload);
    };

    if (authToken && driveFiles.length > 0) {
      handlePickerFileSelection();
    }
  }, [authToken, driveFiles, handleCreateIntegration]);

  // Open picker
  const handlePickerOpen = useCallback(async () => {
    const openPickerInternal = () => {
      openPicker({
        clientId: credentials.client_id,
        developerKey: credentials.developer_key,
        token: authToken,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        setSelectFolderEnabled: true,
        setIncludeFolders: true,
        multiselect: true,
        callbackFunction: async (data) => {
          if (data.action === 'picked' && data.docs) {
            handlePickerFileSelected(data.docs);
          }
        },
      });
    };

    if (!credentials.client_id || !credentials.developer_key) {
      console.error('Google drive Credentials are not loaded yet');
      return;
    }

    if (!isPickerLoaded) {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        window.gapi.load('auth', () => {
          setIsPickerLoaded(true);
          openPickerInternal();
        });
      };
      document.body.appendChild(script);
    } else {
      openPickerInternal();
    }
  }, [
    authToken,
    credentials,
    handlePickerFileSelected,
    openPicker,
    isPickerLoaded,
  ]);

  // Handle auth response
  useEffect(() => {
    if (authRes) {
      setAuthToken(authRes);
    }
  }, [authRes]);

  return { handlePickerOpen, credentials };
};

export default useGoogleDrivePicker;
