import React from 'react';
import './Llmbox.css';
import { useSelector } from 'react-redux';
import {
  convertFirstLetterUppercase,
  getMentorLLMProviderName,
  llmProviderDisplayNames,
  userIsOnTrial,
} from '../../utils/shared';
import useLLMItem from '../../hooks/llm/useLLMItem';
import useSelectMentorLLM from '../../hooks/llm/useSelectLLM';

const Llmbox = (props) => {
  const llm = props.llm;
  const { handleLLMSelected, llmIsSelected } = useSelectMentorLLM(llm);
  const { handleLLMUpdateKeyBtnClick, handleLLMViewBtnClick } = useLLMItem(llm);
  const showSelected = props.showSelected;

  const editMentor = useSelector((state) => state.mentors.edit);

  return (
    <div
      className={`w-layout-vflex llm-prompt-element light-grey-bg-dark-mode greyish-bg-in-darkmode cursor-pointer ${showSelected && llmIsSelected() ? 'selected-llm-prompt-element lightest-grey-bg-dark-mode' : ''}`}
      data-name="llama2"
      id="w-node-cc3a71dd-e361-8e31-6652-2951680b2972-e5765214"
      style={{
        cursor:
          userIsOnTrial() || !llm?.has_credentials ? 'not-allowed' : 'pointer',
      }}
      title={
        userIsOnTrial() ? '' : 'This feature is available in our paid tier'
      }
      map=""
      map-value="llm"
    >
      <div
        className="w-layout-vflex llm-prompt-element-block is-selectable"
        onClick={
          editMentor?.mentor && llm?.has_credentials
            ? handleLLMSelected
            : () => {}
        }
      >
        <div className="w-layout-hflex llm-prompt-element-top-header-block">
          <div className="w-layout-hflex llm-prompt-element-top-header-tag">
            <div className="llm-prompt-element-top-header-choice black-bg-dark-mode">
              {llm?.tags?.tasks?.[0]}
            </div>
            <div className="llm-prompt-element-top-header-choice llm-prompt-choice-no-bg">
              Language
            </div>
          </div>
          <div className="w-layout-hflex llm-prompt-element-top-header-status">
            {llm?.has_credentials ? (
              <div className="llm-prompt-element-top-header-status-dot active"></div>
            ) : (
              <div className="llm-prompt-element-top-header-status-dot"></div>
            )}

            <div className="llm-prompt-element-top-header-status-label">
              {llm?.has_credentials ? (
                <span>Valid Key</span>
              ) : (
                <span>Missing Key</span>
              )}
            </div>
          </div>
        </div>
        <h4 className="llm-prompt-element-title">{llm?.model}</h4>
        <div className="w-layout-vflex llm-prompt-element-desc-block">
          <p className="llm-prompt-element-desc">{llm?.description}</p>
          <div className="llm-prompt-element-desc-info">
            {llmProviderDisplayNames[llm.name] ||
              convertFirstLetterUppercase(llm.name)}
          </div>
        </div>
      </div>
      <div className="w-layout-hflex llm-prompt-element-btn-block">
        <a
          className="llm-prompt-element-btn-link llm-prompt-element-view-details-btn w-inline-block hidden"
          data-w-id="cc3a71dd-e361-8e31-6652-2951680b2980"
          href="#"
          style={{
            cursor: !userIsOnTrial() ? 'pointer' : 'not-allowed',
          }}
          title={
            !userIsOnTrial()
              ? 'View LLM details'
              : 'This feature is available in our paid tier'
          }
          onClick={!userIsOnTrial() ? handleLLMViewBtnClick : () => {}}
          prop-events-value-onclick="handleLLMViewBtnClick"
        >
          <h6 className="llm-prompt-element-btn-label">View Details</h6>
        </a>
        <a
          className="llm-prompt-element-btn-link llm-prompt-element-select-btn w-inline-block"
          data-w-id="974e8843-7141-f2d3-4db6-9d558ba4e5f4"
          href="#"
          style={{
            cursor: !userIsOnTrial() ? 'pointer' : 'not-allowed',
          }}
          title={
            !userIsOnTrial()
              ? 'Set LLM Key'
              : 'This feature is available in our paid tier'
          }
          onClick={!userIsOnTrial() ? handleLLMUpdateKeyBtnClick : () => {}}
          prop-events-value-onclick="handleLLMUpdateKeyBtnClick"
        >
          <h6 className="llm-prompt-element-btn-label">Update Key</h6>
        </a>
      </div>
    </div>
  );
};

export default Llmbox;
